<template>
		<div>
			<div
				class=center
				v-if="this.$root.investor.isMA==true"
			>
					<router-link to="/messages">
						Check Messages	
					</router-link>
			</div>
			<div 
				class="contact_cont"
				v-if="success==false"
			>
				<div class="full">
					<h3>Contact Us</h3>
					<v-text-field
						label="First Name"
						:rules="[rules.counter, rules.required]"
						counter
						maxlength="30"
						v-model=contact.first_name
					>
					</v-text-field>
					<v-text-field
						label="Last Name"
						:rules="[rules.counter, rules.required]"
						counter
						maxlength="30"
						v-model=contact.last_name
					>
					</v-text-field>
					<v-text-field
						label="Agency/Organization"
						v-model=contact.company
						counter
						maxlength="30"
						:rules="[rules.counter]"
					>
					</v-text-field>
					<v-text-field
						label="e-mail"
						:rules="[rules.required, rules.email]"
						v-model=contact.email
					>
					</v-text-field>
					<v-text-field
						label="Phone"
						:rules="[rules.required]"
						v-model=contact.phone
					>
					</v-text-field>
					<v-text-field
						class="hp"
						label="Website"
						v-model=contact.website
					>
					</v-text-field>

					<v-select
						v-model=contact.regarding
						:items="regarding"	
						label="Regarding"
					>
						<template #search="{attributes, events}">
							<input
								class="vs__search"
								:required="!selected"
								v-bind="attributes"
								v-on="events"
							/>
						</template>
					</v-select>

					<br>
					<br>
					
					<v-textarea
						height="200px"
						outlined
						counter="4096"
						name="Message"
						label="Message"
						:rules="rules.big_counter"
						v-model="contact.message"
					>
					</v-textarea>
					<v-btn
						outlined
						v-on:click='save($event)'
					>
						Send	
					</v-btn>
				</div>	
			</div>
			<div v-else>
				<center>
					<h3>Thank you for your comment.	We will respond shortly.</h3>
					<router-link to="/">
						Home
					</router-link>
					<br>
				</center>
			</div>
		</div>
</template>

<script>
module.exports = {
	data() {
		return {
			success: false,
			contact: {
				"first_name": "",
				"last_name": "",
				"company": "",
				"email": "",
				"phone": "",
				"website": "",
				"regarding": "",
				"message": "",
				"user_id": -1
			},
			rules: {
				counter: value => value.length <= 30 || 'Max 30 characters',
				big_counter: value => value.length <= 4096 || 'Max 4096 characters',
				required: value => !!value || 'Required.',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				},
			},
			regarding: [ 
				"Sales",
				"Support",
				"Question",
				"Comment",
				"Nonprofit Status",
				"Integrations", 
				"Dedicated Instance",
				"Test Account",
				"Other"
			],
			titleFontStyle: {},
			titleFontStyleMobile: {},
			textFontStyle: {},
			textFontStyleMobile: {}, };
	},
	methods: {
		changed() {
		},
		save(e) {

			if(this.contact==null||this.contact.contact_id==-1) {
				return;
			}
			
			var ep = "/svc/contact/"
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			var d = {}
			d.first_name = this.contact.first_name
			d.last_name = this.contact.last_name
			d.company = this.contact.company
			d.email = this.contact.email
			d.phone = this.contact.phone
			d.website = this.contact.website
			d.regarding = this.contact.regarding
			d.message = this.utf8_to_b64(this.contact.message)
			d.user_id = this.contact.user_id

			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;

			this.$http.post(ep, d, { headers }).then(response => {
				if(response.data.code==200) {
					this.success=true;
				} else if(response.data.code==601) {
					alert("Please fill in all required fields.");
				} else {
					alert("Unable to save:	" + response.data.description);
				}
			})
			
		},
		getReadableDate(ts) {
			console.log(ts);
			var t = new Date(ts*1000);
			//var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			var options = { year: 'numeric', month: 'long', day: 'numeric' };
			var times = t.toLocaleDateString("en-US", options);
			console.log(times);
			return(times);
		},

		utf8_to_b64( str ) {
			return window.btoa(unescape(encodeURIComponent( str )));
		},

		b64_to_utf8( str ) {
			return decodeURIComponent(escape(window.atob( str )));
		}
	},
	mounted() {
		if(this.$root.investor && this.$root.investor.loggedIn) {
			this.contact.first_name=this.$root.investor.FirstName
			this.contact.last_name=this.$root.investor.LastName
			this.contact.user_id=this.$root.investor.uid
			this.contact.company="TBL Systems, Inc"
			this.contact.email=this.$root.investor.Email
		}	
	}

};
</script>

<style scoped>
.contact_cont {
	max-width: 1200px;
	height: 100%;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
}

.full {
}

.left_cont {
	display: inline;
	width: 20%;
	float: left;
}
.right_cont {
	display: inline;
	width: 20%;
	float: left;
}
.main_cont {
	display: inline;
	width: 60%;
	float: left;
}
.primary_img {
	max-height: 200px;
	max-width: 700px;
	object-fit: cover;
}

.mobile {
	margin: 0px 10px 0px 10px;
}

.hp {
	display: none;
}

</style>


<style>
blockquote {
	padding: 0 1em;
	border-left: 3px solid rgba(0,0,0,.05);
	margin: 0 0 1.41575em 3em;
	font-style: italic;
}
hr {
	width:80%;
	text-align:left;
	margin-left:0;
	color: rgba(0,0,0,0.05);
}
ol, ul {
	margin: 0 0 1.41575em 3em;
	padding: 0;
}
</style>
